import React from 'react'

import pageContext from './pageContext.json'
import { Wrapper } from '../../etica-e-compliance/style'

import Layout from 'src/layouts/BaseLayout'

import ProgramaIntegridade from '../../etica-e-compliance/sections/programa-integridade/_index'
import CodigoCondutaEtica from '../../etica-e-compliance/sections/codigo-conduta-etica/_index'
import NossosPilares from '../../etica-e-compliance/sections/nossos-pilares/_index'
import NossasPoliticas from '../../etica-e-compliance/sections/nossas-politicas/_index'
import CanalDeEtica from '../../etica-e-compliance/sections/canal-de-etica/_index'
import ProcessoDeDenuncia from '../../etica-e-compliance/sections/processo-de-denuncia/_index'
import RelacaoComFornecedores from '../../etica-e-compliance/sections/relacao-com-fornecedores/_index'
import Anticorrupcao from '../../etica-e-compliance/sections/anticorrupcao/_index'
import Legislacao from '../../etica-e-compliance/sections/legislacao/_index'

const Integridade = () => {
  const lang = 'en'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <ProgramaIntegridade lang={lang} />
        <CodigoCondutaEtica lang={lang} />
        <NossosPilares lang={lang} />
        <NossasPoliticas lang={lang} />
        <CanalDeEtica lang={lang} />
        <ProcessoDeDenuncia lang={lang} />
        <RelacaoComFornecedores lang={lang} />
        <Anticorrupcao lang={lang} />
        <Legislacao lang={lang} />
      </Layout>
    </Wrapper>
  )
}

export default Integridade
